import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from './AppInsights';

export function logCustomMessage(
    message: string, 
    severityLevel: SeverityLevel, 
    apiCall?: string, 
    requestParams?: any, 
    response?: any
  ) {
    const details = {
      message,
      severityLevel,
      properties: {
        apiCall,
        requestParams: JSON.stringify(requestParams),
        response: JSON.stringify(response)
      }
    };
  
    appInsights.trackTrace(details);
  }