import { IonCard, IonLabel, useIonAlert } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import {
  CriteriaParameter,
  FilterParam,
  QueryResult,
} from "../hooks/useQueryExec";
import { encodeParam } from "../util/ApiHelper";
import { TranslationMessagesContext } from "../util/Translations";
import {
  getUserRequesterAccess,
  getUserTechnicianAccess,
} from "../util/UserHelper";
import CardInfo from "./CardInfo";
import CardFilter from "./CardFilter";

interface WorkOrderResultsParams {
  searchYn: boolean;
  setSearchYn: (arg0: boolean) => void;
  hideFilter?: boolean;
  queryName: string;
  queryParams?: FilterParam[];
  criteria?: CriteriaParameter[];
  queryRows: QueryResult[];
  setParameter?: (
    paramKey: string,
    paramValue: string | number | null | undefined
  ) => void;
  executeSearch: (
    queryName: string,
    parameters?: CriteriaParameter[]
  ) => Promise<void>;
  getPriorityCodes?: () => void;
  getStatusCodes?: () => void;
  getNoOfFieldsToDisplay: (option: string) => void;
  errorState: string;
  totalCount: number;
  showLoading: boolean;
  setShowLoading: (arg0: boolean) => void;
  onQueryRowClick?: (row: QueryResult) => void;
  filterRows: QueryResult[];
  filterMethod: (queryString: string) => Promise<void>;
  filterString: string;
  storesRoute?: boolean;
  storesTab?: string;
}

const WorkOrderResults: React.FC<WorkOrderResultsParams> = (props) => {
  const {
    searchYn,
    setSearchYn,
    hideFilter,
    queryName,
    queryParams,
    criteria,
    queryRows,
    setParameter,
    executeSearch,
    getPriorityCodes,
    getNoOfFieldsToDisplay,
    errorState,
    totalCount,
    showLoading,
    setShowLoading,
    onQueryRowClick,
    filterRows,
    filterMethod,
    filterString,
    storesRoute,
    storesTab,
  } = props;
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [presentAlert] = useIonAlert();
  const [woLink, setWoLink] = useState("");

  useEffect(() => {
    setShowLoading(true);
    loadData().finally(() => {
      setShowLoading(false);
    });
  }, [searchYn]);

  const loadData = async () => {
    await setWorkOrderPath();
    await setQueryParams();
    if (getPriorityCodes) {
      getPriorityCodes();
    }
    getNoOfFieldsToDisplay("NoOfFieldsToDisplay");
    await beginSearch();
  };

  const setQueryParams = async () => {
    if (setParameter && queryParams) {
      queryParams.forEach((p) => {
        setParameter(p.ColumnName, p.ParameterValue);
      });
    }
  };
  async function setWorkOrderPath() {
    if (await getUserTechnicianAccess()) {
      setWoLink("/workorder");
    } else if (await getUserRequesterAccess()) {
      setWoLink("/requester/workorder");
    }
  }
  const beginSearch = async () => {
    if (searchYn) {
      setShowLoading(true);
      await executeSearch(queryName, criteria);
      setSearchYn(false);
      setSearchExecuted(true);
      setShowLoading(false);
    }
  };

  return (
    <>
      {errorState ? (
        <div style={{ padding: 8 }} hidden={errorState === ""}>
          <IonLabel color={"danger"}>{errorState}</IonLabel>
        </div>
      ) : (
        <div
          style={{ padding: 8 }}
          hidden={showLoading || !searchExecuted || totalCount > 0}
        >
          <IonLabel color={"warning"}>
            {translatedMessages["NoResultsFound"]?.MessageText ||
              "No results found."}
          </IonLabel>
        </div>
      )}

      {!!queryRows && queryRows.length > 0 ? (
        <>
          <CardFilter
            hideFilter={hideFilter}
            queryRows={queryRows}
            resultsFilter={filterString}
            filterMethod={filterMethod}
          />
          {filterRows.map(function (row) {
            return (
              <IonCard
                style={
                  row.HeaderColor !== ""
                    ? {
                        borderColor: row.HeaderColor,
                        borderLeftStyle: "solid",
                        borderLeftWidth: "5px",
                      }
                    : {}
                }
                key={row.HeaderRow}
                // navigate to work order details screen from work order/main page and stores
                //stores may change to go to issue or returns directly
                routerLink={
                  row.HeaderValue !== ""
                    ? storesRoute
                      ? `/workorder/${encodeParam(
                          row.HeaderValue
                        )}/${storesTab}material`
                      : `${woLink}/${encodeParam(row.HeaderValue)}`
                    : undefined
                }
                onClick={() => {
                  if (row.HeaderValue === "") {
                    presentAlert(
                      translatedMessages[
                        "QWColumnMissing"
                      ]?.MessageText.replace("@1", row.HeaderValue) ||
                        `Cannot continue with this request. The column ${row.HeaderValue} is not selected in the query. Please rebuild the query and select this column.`
                    );
                  }
                  if (onQueryRowClick) {
                    onQueryRowClick(row);
                  }
                }}
              >
                <CardInfo header={row.HeaderValue} columns={row.Columns} />
              </IonCard>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default withAITracking(
  reactPlugin,
  WorkOrderResults,
  "WorkOrderResults"
);