import { Device, DeviceInfo } from "@capacitor/device";
import { ScreenOrientation, OrientationType } from "@capawesome/capacitor-screen-orientation";
import { get, set, remove } from "./Preferences";

const _deviceInfoKey = "DeviceInfo";
const _screenOrientationKey = "ScreenOrientation";

export const getDeviceInfo = async () => {
    let deviceInfo = await get(_deviceInfoKey) as DeviceInfo;
    if (!deviceInfo) {
        deviceInfo = await Device.getInfo();
        await remove(_deviceInfoKey);
        set(_deviceInfoKey, deviceInfo);
    }
    return deviceInfo;
};

export const getScreenOrientation = async () => {
    let currentOrientation = await get(_deviceInfoKey) as OrientationType;
    if (!currentOrientation) {
        const orientation = await ScreenOrientation.getCurrentOrientation();
        currentOrientation = orientation.type;
        set(_screenOrientationKey, currentOrientation);
    }
    return currentOrientation;
};

export const setScreenOrientation = async (orientationType: OrientationType) => {
    set(_screenOrientationKey, orientationType);
};

export const isLandscapeMode = async (orientationType: OrientationType) => {
    switch (orientationType) {
        case OrientationType.LANDSCAPE:
        case OrientationType.LANDSCAPE_PRIMARY:
        case OrientationType.LANDSCAPE_SECONDARY:
            return true;
        case OrientationType.PORTRAIT:
        case OrientationType.PORTRAIT_PRIMARY:
        case OrientationType.PORTRAIT_SECONDARY:
        default:
            return false;
    }
};