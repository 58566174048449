import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCCAFieldDefinitions,
  getMappingSource,
  getCompleteColumnDetails,
  getCCACascadeValues,
} from "../api/AnalysisCodes";
import {
  AnalysisCode,
  CascadeValue,
  CompletionFieldDef,
  ValidValue,
} from "../models/analysiscodes/AnalysisCodes";
import { decodeParam } from "../util/ApiHelper";
import "./AnalysisCodes.css";
import { WorkOrder } from "../models/workorders/WorkOrder";
import { TranslationsContext } from "../util/Translations";
import { getWorkOrder } from "../api/WorkOrders";
import ApiError from "./ApiError";

interface AnalysisCodesModalProps {
  isOpen: boolean;
  analysisCodeForEdit?: AnalysisCode;
  analysisCodes: AnalysisCode[];
  onCancel: () => void;
  onSave: (analysisCode?: AnalysisCode) => void;
}

const AnalysisCodesModal: React.FC<AnalysisCodesModalProps> = (props) => {
  const { isOpen, analysisCodeForEdit, analysisCodes, onCancel, onSave } =
    props;
  const { translations } = useContext(TranslationsContext);
  const { workOrderId } = useParams<{ workOrderId: string }>();
  const displayWo = decodeParam(workOrderId);
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [analysisCode, setAnalysisCode] = useState<AnalysisCode>();
  const [componentField, setComponentField] = useState<CompletionFieldDef>();
  const [conditionField, setConditionField] = useState<CompletionFieldDef>();
  const [actionField, setActionField] = useState<CompletionFieldDef>();
  const [showLoading, setShowLoading] = useState(false);
  const [isNewRelationship, setIsNewRelationship] = useState<boolean>();
  const [componentValues, setComponentValues] = useState<ValidValue[]>([]);
  const [conditionValues, setConditionValues] = useState<ValidValue[]>([]);
  const [actionValues, setActionValues] = useState<ValidValue[]>([]);
  const [componentCascadeValues, setComponentCascadeValues] = useState<
    CascadeValue[]
  >([]);
  const [conditionCascadeValues, setConditionCascadeValues] = useState<
    CascadeValue[]
  >([]);
  const [actionCascadeValues, setActionCascadeValues] = useState<
    CascadeValue[]
    >([]);
  const [errorData, setErrorData] = useState('');

  useEffect(() => {
    if (displayWo) {
      setup(displayWo);
    }
  }, [displayWo]);

  useEffect(() => {
    setAnalysisCode(analysisCodeForEdit);
  }, [analysisCodeForEdit]);

  //TODO: Filter component values by WorkOrder.EquipmentClass

  useEffect(() => {
    // If the selected component has mapped conditions, filter through all conditions to show only the valid options. Otherwise, show all condition values.
    if (isNewRelationship && analysisCode?.Component) {
      const cascadeValues = conditionCascadeValues.filter((cascadeValue) => {
        return cascadeValue.ParentValue === analysisCode.Component;
      });
      const allConditionValues = conditionField?.ValidValues || [];
      if (cascadeValues.length > 0) {
        const conditionCodes = cascadeValues?.map((cascadeValue) => {
          return cascadeValue.ChildValue;
        });
        const filteredValues = allConditionValues.filter((validValue) => {
          return conditionCodes.includes(validValue.Code);
        });
        setConditionValues(filteredValues);
      } else {
        setConditionValues(allConditionValues);
      }
      if (
        !analysisCodeForEdit ||
        (analysisCode.Component &&
          analysisCode.Component !== analysisCodeForEdit?.Component) ||
        (analysisCode.Condition &&
          analysisCode.Condition !== analysisCodeForEdit?.Condition)
      ) {
        setAnalysisCode({
          ...analysisCode,
          Condition: undefined,
          Action: undefined,
        });
      }
    }
  }, [analysisCode?.Component]);

  useEffect(() => {
    // If the selected condition has mapped conditions, filter through all action to show only the valid options. Otherwise, show all action values.
    if (isNewRelationship && analysisCode?.Condition) {
      const cascadeValues = actionCascadeValues.filter((cascadeValue) => {
        return (
          cascadeValue.ParentValue === analysisCode.Condition &&
          cascadeValue.AncestorValue === analysisCode.Component
        );
      });
      const allActionValues = actionField?.ValidValues || [];
      if (cascadeValues.length > 0) {
        const actionCodes = cascadeValues?.map((cascadeValue) => {
          return cascadeValue.ChildValue;
        });
        const filteredValues = allActionValues.filter((value) => {
          return actionCodes.includes(value.Code);
        });
        setActionValues(filteredValues);
      } else {
        setActionValues(allActionValues);
      }
      if (
        !analysisCodeForEdit ||
        (analysisCode.Condition &&
          analysisCode.Condition !== analysisCodeForEdit?.Condition) ||
        (analysisCode.Action &&
          analysisCode.Action !== analysisCodeForEdit?.Action)
      ) {
        setAnalysisCode({ ...analysisCode, Action: undefined });
      }
    }
  }, [analysisCode?.Condition]);

  const setup = async (workOrderId: string) => {
    setShowLoading(true);

    const woResponse = await getWorkOrder({ woNumber: workOrderId, events: true, equipmentMaster: true });
    if (woResponse.isError) {
      setErrorData(woResponse.data);
    } else {
      const workOrder = woResponse.data as WorkOrder;
      setWorkOrder(workOrder);
      const fields = await getCCAFieldDefinitions(workOrder?.Equipment);
      !!fields?.componentField && setComponentField(fields.componentField);
      !!fields?.conditionField && setConditionField(fields.conditionField);
      !!fields?.actionField && setActionField(fields.actionField);

      const mappingSource = await getMappingSource();
      const isNewRelationship =
        !!mappingSource && mappingSource === "NewRelationship";
      setIsNewRelationship(isNewRelationship);

      let componentValues = fields?.componentField?.ValidValues;
      let conditionValues = fields?.conditionField?.ValidValues;
      let actionValues = fields?.actionField?.ValidValues;
      if (!isNewRelationship && workOrder?.EquipmentClass) {
        const oldRelColumns = await getCompleteColumnDetails(
          workOrder?.Equipment
        );
        componentValues = oldRelColumns?.component;
        conditionValues = oldRelColumns?.condition;
        actionValues = oldRelColumns?.action;
      }
      setComponentValues(componentValues || []);
      setConditionValues(conditionValues || []);
      setActionValues(actionValues || []);

      if (isNewRelationship) {
        const {
          componentCascadeValues,
          conditionCascadeValues,
          actionCascadeValues,
        } = await getCCACascadeValues();
        setComponentCascadeValues(componentCascadeValues);
        setConditionCascadeValues(conditionCascadeValues);
        setActionCascadeValues(actionCascadeValues);
      }
    }
    setShowLoading(false);
  };

  const onComponentSelect = (value?: string) => {
    value && setAnalysisCode({ ...analysisCode, Component: value });
  };
  const onConditionSelect = (value?: string) => {
    value && setAnalysisCode({ ...analysisCode, Condition: value });
  };
  const onActionSelect = (value?: string) => {
    value && setAnalysisCode({ ...analysisCode, Action: value });
  };

  const isValidCode = () => {
    if (
      !analysisCode ||
      !analysisCode.Component ||
      !analysisCode.Condition ||
      !analysisCode.Action
    ) {
      return false;
    }
    const doesExist = analysisCodes.find((code) => {
      return (
        code.Component === analysisCode?.Component &&
        code.Condition === analysisCode?.Condition &&
        code.Action === analysisCode?.Action
      );
    });
    return !doesExist;
  };

  const resetSelectOptions = () => {
    if (isNewRelationship) {
      setComponentValues(componentField?.ValidValues || []);
      setConditionValues(conditionField?.ValidValues || []);
      setActionValues(actionField?.ValidValues || []);
    }
  };

  const handleCancel = () => {
    onCancel();
    resetSelectOptions();
  };
  const handleSave = () => {
    onSave(analysisCode);
    setAnalysisCode(undefined);
    resetSelectOptions();
  };

  return (
    <IonModal isOpen={isOpen} onIonModalWillDismiss={onCancel}>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => {
          setShowLoading(false);
        }}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleCancel}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {analysisCodeForEdit
              ? `${translations["lbl_edit"] || "Edit"} `
              : `${translations["lbl_button_add"] || "Add"} `}
            {translations["lbl_analysis_codes"] || "Analysis Code"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              onClick={handleSave}
            >
              {translations["lbl_btn_save"] || "Save"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <ApiError errorData={errorData} />
      <IonContent>
        <IonList lines="full">
          <IonItem>
            <IonLabel position="stacked">
              {componentField?.TranslatedIdText || "Component"}
            </IonLabel>
            <IonSelect
              interface="popover"
              value={analysisCode?.Component}
              onIonChange={(ev) => {
                onComponentSelect(ev.detail.value);
              }}
            >
              {componentValues.map((value, index) => {
                return (
                  <IonSelectOption
                    key={`${value.Code}-${index}`}
                    value={value.Code}
                  >
                    {value.Code}
                    {!!value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              {conditionField?.TranslatedIdText || "Condition"}
            </IonLabel>
            <IonSelect
              interface="popover"
              value={analysisCode?.Condition}
              onIonChange={(ev) => {
                onConditionSelect(ev.detail.value);
              }}
            >
              {conditionValues.map((value, index) => {
                return (
                  <IonSelectOption
                    key={`${value.Code}-${index}`}
                    value={value.Code}
                  >
                    {value.Code}
                    {!!value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              {actionField?.TranslatedIdText || "Action"}
            </IonLabel>
            <IonSelect
              interface="popover"
              value={analysisCode?.Action}
              onIonChange={(ev) => {
                onActionSelect(ev.detail.value);
              }}
            >
              {actionValues.map((value, index) => {
                return (
                  <IonSelectOption
                    key={`${value.Code}-${index}`}
                    value={value.Code}
                  >
                    {value.Code}
                    {!!value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default AnalysisCodesModal;
