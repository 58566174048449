import { IonLoading } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { handlePWACallback } from "../util/OAuth";
import { TranslationMessagesContext } from "../util/Translations";

const PortalAuthCallback: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const { translatedMessages } = useContext(TranslationMessagesContext);

  useEffect(() => {
    setShowLoading(true);
    getAccessTokenFromUrl();
  }, []);

  const getAccessTokenFromUrl = async () => {
    console.log("PortalAuthCallback - getAccessTokenFromUrl");
    const response = await handlePWACallback(window.location.href, true);
    console.log("PortalAuthCallback - handlePWACallback response: ", response);

    if (window.location.href.includes('access_denied') && window.location.href.includes('The+user+has+forgotten+their+password')) {
      setTimeout(() => {
        window.location.href = `/AuthCallback${window.location.hash}`;
      }, 1000);
    }
    else {
      setTimeout(() => {
        window.location.href = "/AuthCallback";
      }, 1000);
    }
  };

  return (
    <IonLoading
      cssClass="my-custom-class"
      isOpen={showLoading}
      onDidDismiss={() => setShowLoading(false)}
      message={`${
        translatedMessages["ValUser"]?.MessageText || "Validating user"
      }...`}
      duration={15000}
    />
  );
};

export default PortalAuthCallback;
