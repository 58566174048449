import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonLoading,
  RefresherEventDetail,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonTitle,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryExec } from "../hooks/useQueryExec";
import { decodeParam } from "../util/ApiHelper";
import { getCurrentPlant } from "../util/ApiOptionsHelper";
import {
  formatData,
  getAllFields,
  MeterReadings,
  MeterReadingsMetadata,
} from "../models/workorders/MeterReading";
import MeterReadingsModal from "./MeterReadingsModal";
import OnlineStatus from "./OnlineStatus";
import {
  TranslationsContext,
  TranslationMessagesContext,
} from "../util/Translations";
import { getOptionByIdWithCORP } from "../api/TabwareOptions";
import { getWorkOrder } from "../api/WorkOrders";
import { getColumnsWithGroup } from "../api/Windows";
import { getMeterReadings } from "../api/MeterReadings";

const MeterReadingHistory: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { errorState } = useQueryExec(
    "Work Order",
    "Work Order",
    "cf_work_order"
  );
  const { workOrderId, meter } = useParams<{
    workOrderId: string;
    meter: string;
  }>();
  let displayMeter = decodeParam(meter);
  const [metadataHistory, setMetadataHistory] =
    useState<MeterReadingsMetadata>();
  const [meterReadingHistory, setMeterReadingHistory] = useState<
    MeterReadings[]
  >([]);
  let [equipSerial, setEquipSerial] = useState<string>("");
  let [isSerial, setIsSerial] = useState<string>("");
  let [readingDate, setReadingDate] = useState<string>("");
  let [readingTime, setReadingTime] = useState<string>("");
  let [reading, setReading] = useState<number>(0);
  let [comments, setComments] = useState<string>("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [inRefresh, setInRefresh] = useState(false);
  const [noOfRecords, setNoOfRecords] = useState<number>(0);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setInRefresh(true);
    event.detail.complete();
  }

  const getNoOfRecords = () => {
    getOptionByIdWithCORP("NoOfMeterHistoryDisplay").then((response) => {
      const number = !!response.data.PlantValue
        ? response.data.PlantValue
        : response.data.DefaultValue;
      if (number && !Number.isNaN(+number)) {
        setNoOfRecords(+number);
      }
    });
  };

  const getEquipSerial = () => {
    const httpParams = {
      woNumber: decodeParam(workOrderId),
    };
    getWorkOrder(httpParams).then((response) => {
      setEquipSerial(response.data.Equipment);
      if (response.data.WriteWoToSerialFlag === true) {
        setIsSerial("s");
      } else {
        setIsSerial("e");
      }
    });
  };

  const metadataQueryHistory = async () => {
    const data = {
      IncludeValidValues: false,
      IncludeValidValuesExceptions: [],
      IsReadOnly: true,
      ValidValueFilters: [],
      WindowName: "mob_meter_history",
      ContextPKey: {
        Plant: await getCurrentPlant(),
        WoBase: "",
        WoTask: "  ",
        WoSubtask: "  ",
      },
    };
    return getColumnsWithGroup(data).then((response) => {
      setMetadataHistory(response.data);
    });
  };

  const meterReadingQueryHistory = () => {
    getMeterReadings({
      equipSerialId: equipSerial,
      equipSerialInd: isSerial,
      meters: displayMeter,
    }).then((response) => {
      let data = response.data;
      let query = [] as any[];
      for (let i = data.length - 1; i >= 0; i--) {
        query.push(data[i]);
      }
      setMeterReadingHistory(query);
      // Hide loading symbol once all the calls are done
      setShowLoading(false);
    });
  };

  useEffect(() => {
    getNoOfRecords();
    getEquipSerial();
    //Reset data when switching meter readings.
    setMeterReadingHistory([]);
    // Show loading symbol
    setShowLoading(true);
    // Run api calls to get metadata and meter reading.
    metadataQueryHistory().then(() => {
      if (equipSerial && isSerial) {
        meterReadingQueryHistory();
      }
    });
  }, [equipSerial, workOrderId, inRefresh]);

  const onAddModalCancel = () => {
    setIsAddModalOpen(false);
  };
  const onAddModalConfirm = () => {
    setIsAddModalOpen(false);
    meterReadingQueryHistory();
  };

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <OnlineStatus />
      {isAddModalOpen && (
        <MeterReadingsModal
          isOpen={isAddModalOpen}
          cancel={onAddModalCancel}
          confirm={onAddModalConfirm}
          readDate={readingDate}
          readTime={readingTime}
          reading={reading}
          comments={comments}
          equipSerial={equipSerial}
          setEquipSerial={setEquipSerial}
          isSerial={isSerial}
          setIsSerial={setIsSerial}
          getEquipSerial={getEquipSerial}
        />
      )}
      <IonTitle
        style={{ padding: "12px", margin: "5px", display: "inline-block" }}
      >
        {`${translations["lbl_category_meter"] || "Meter"}: ${displayMeter}`}
      </IonTitle>
      {!!metadataHistory &&
        !!meterReadingHistory &&
        meterReadingHistory.map((meterHistory: any, index) => {
          if (index < noOfRecords) {
            return (
              <IonCard
                key={index}
                // WILL BE UNCOMMENTED ONCE WE REQUIRE EDIT FUNCTIONALITY
                // onClick={() => {
                //   setReadingDate(meterHistory.ReadingDate);
                //   setReadingTime(meterHistory.ReadingTime);
                //   setReading(meterHistory.Reading);
                //   setComments(meterHistory.Comments);
                //   setIsAddModalOpen(true);
                // }}
              >
                <IonCardContent>
                  <IonList lines="full">
                    {getAllFields(metadataHistory).map(
                      (meterHistoryField: any) => {
                        const data =
                          meterHistory[meterHistoryField.PropertyName];
                        return (
                          <IonItem key={meterHistoryField.IdText}>
                            <IonLabel>
                              {meterHistoryField.TranslatedIdText}
                            </IonLabel>
                            {meterHistoryField.PropertyName === "ReadingDate"
                              ? formatData(
                                  meterHistory.ReadingDate,
                                  meterHistoryField
                                )
                              : meterHistoryField.PropertyName === "ReadingTime"
                              ? meterHistory.ReadingTime
                              : meterHistoryField.PropertyName === "Reading"
                              ? meterHistory.Reading
                              : formatData(data, meterHistoryField)}
                          </IonItem>
                        );
                      }
                    )}
                  </IonList>
                </IonCardContent>
              </IonCard>
            );
          }
        })}
      <IonLabel hidden={errorState === ""} color={"danger"}>
        {errorState}
      </IonLabel>
      {meterReadingHistory.length === 0 && (
        <IonLabel
          hidden={showLoading}
          color={"warning"}
          style={{ padding: "16px", display: "inline-block" }}
        >
          {translatedMessages["NoResultsFound"]?.MessageText ||
            "No results found."}
        </IonLabel>
      )}
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton
          onClick={() => {
            setReadingDate("");
            setReadingTime("");
            setReading(0);
            setComments("");
            setIsAddModalOpen(true);
          }}
        >
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default MeterReadingHistory;
