import React, { useContext, useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLoading,
  useIonRouter,
} from "@ionic/react";
import {
  getPortalBearerToken,
  handlePWACallback,
  signin,
  signinPortal,
  signoutPortal,
  validateBearerToken,
} from "../util/OAuth";
import { initializeAbpSession } from "../api/abpLogin";
import ApiOptionsModal from "../components/ApiOptionsModal";
import {
  clearApiSession,
  getApiOptionsData,
  getApiSession,
  mobileBootstrap,
} from "../util/ApiOptionsHelper";
import ApiError from "../components/ApiError";
import { getRequestedURL } from "../util/UrlHelper";
import { TranslationMessagesContext } from "../util/Translations";

const AuthCallback: React.FC = () => {
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const [showLoading, setShowLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  let isModalOpen = false;
  const router = useIonRouter();

  useEffect(() => {
    setShowLoading(true);
    if (inProcess) return;

    setInProcess(true);
    validateUser();
  }, []);

  const validateUser = async () => {
    if (isCallback(window.location.href)) {
      const response = await getAccessTokenFromUrl();
      if (!response.validLogin) {
        if (!isModalOpen) {
          setShowLoading(false);
          setLoginError(true);
          setLoginErrorMessage(response.message);
        }
      } else if(globalThis.isLoginSuccessful){
        const returnPath = await getRequestedURL();
        setTimeout(() => {
          router.push(returnPath);
        }, 2000);
      }
    } else {
      // b2c login or abp login
      const response = await authenticateUser();
      console.log('response: ', response);
      setInProcess(false);
      if (!response.validLogin) {
        if (!isModalOpen) {
          console.log(
            "validateUser - authenticateUser: User login failed in auth callback"
          );
          setShowLoading(false);
          setLoginError(true);
          setLoginErrorMessage(response.message)
        }
      } else if(globalThis.isLoginSuccessful){
        const returnPath = await getRequestedURL();
        setTimeout(() => {
          router.push(returnPath);
        }, 2000);
      }
    }
  };

  const isCallback = (url: string) => {
    if (
      !!url &&
      url.indexOf("state") > -1 &&
      url.indexOf("access_token") > -1
    ) {
      return true;
    }
    return false;
  };

  const getAccessTokenFromUrl = async () => {
    const response = await handlePWACallback(window.location.href, false);
    if (!response) {
      return {validLogin: false, message: ''};
    }

    //abplogin
    return await validateABPLicense();
  };

  const authenticateUser = async () => {
    //check if portal authentication is needed
    const portalToken = await getPortalBearerToken();
    if (!!!portalToken) {
      setLoginError(false);
      setLoginErrorMessage('');
      //authenticate user in portal
      await signinPortal();
    }

    //check if mobileBootstrap data is available
    await mobileBootstrap();

    //logout of portal
    await signoutPortal();
    
    //check for plant/db - apiSession
    if (await checkApiSession()) {
      return validateEAMSession();
    }
    return {validLogin: false, message: ""};
  };

  const validateEAMSession = async () => {
    //check b2c on eam
    if (!await validateBearerToken()) {
      //b2c authentication on eam
      const signedIn = await signin();
      if (!signedIn) {
        return { validLogin: false, message: ""};
      }
    }
    //abplogin
    return await validateABPLicense();
  };

  const checkApiSession = async () => {
    const apiSession = await getApiSession();
    if (
      !!apiSession &&
      !!apiSession?.PlantCode &&
      !!apiSession?.DatabaseName &&
      !!apiSession.BackendApiUrl &&
      !!apiSession.B2CUserFlowScope
    ) {
      return true;
    }
    if (!isModalOpen) {
      setIsOpen(true);
      isModalOpen = true;
      setShowLoading(false);
    }
    return false;
  };

  const validateABPLicense = async () => {
    const abpLogin = await initializeAbpSession();
    if (!abpLogin.validLogin) {
      //clear the api options selection
      await clearApiSession();
    }
    return abpLogin;
  };

  const confirmApiOptions = async (cancel: boolean) => {
    setShowLoading(true);

    //close modal
    setIsOpen(false);
    isModalOpen = false;
    //redirect to logout if cancelled
    if (cancel) {
      globalThis.relogin = true;
      router.push("/logout");
	    return false;
    } else {
      const eamSessionValid = await validateEAMSession();
      if (eamSessionValid.validLogin) {
        const returnPath = await getRequestedURL();
        setTimeout(() => {
          setShowLoading(false);
          router.push(returnPath);
        }, 2000);
      }
      return eamSessionValid.validLogin;
    }
  };

  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={`${
          translatedMessages["ValUser"]?.MessageText || "Validating user"
        }...`}
        duration={15000}
      />
      <IonHeader>
        <IonToolbar>
          <IonTitle>EAM Mobile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ApiError
        errorData={
          loginError
            ? (loginErrorMessage === ""
                ? translatedMessages["LoginFailed"]?.MessageText || "Login failed, try again after restarting the app."
                : loginErrorMessage
              )
            : ""
        } showRelogin={true}
      />
      <IonContent className="ion-padding">
        <ApiOptionsModal
          isOpen={isOpen}
          isInit={true}
          confirmCallback={confirmApiOptions}
        />
      </IonContent>
    </IonPage>
  );
};

export default AuthCallback;
