import React from 'react';
import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import { Browser } from '@capacitor/browser';
import { get, set, remove } from '../util/Preferences';
import { isPlatform } from '@ionic/react';
import authConfig from "../authConfig.json";
import { clearApiOptionsData, clearApiSession, getApiSession } from './ApiOptionsHelper';

const BEARER_TOKEN = "BearerToken";
const BEARER_TOKEN_PARSED = "BearerTokenParsed";
const BEARER_TOKEN_PORTAL = "BearerTokenPortal";
const BEARER_TOKEN_PORTAL_PARSED = "BearerTokenPortalParsed";
const PORTAL_USER_UNIQUEID = "PortalUserUniqueId";
const B2C_SCOPE_CURRENT = "CurrentB2CScope";

interface AuthContent {
    loggedIn: boolean | undefined;
    setLoggedIn: ((loggedIn: boolean) => void) | undefined,
}

export const AuthContext = React.createContext<AuthContent>({
    loggedIn: false,
    setLoggedIn: undefined
});

export const signin = async () => {
    const oAuthOptions = await getOAuthOptions(false);
    return await OAuth2Client.authenticate(oAuthOptions).then(async response => {
        let accessTokenResponse = response['access_token_response'];
        let jwtToken = accessTokenResponse['access_token'];
        await storeAuthData(jwtToken);
        //store b2c scope used
        await set(B2C_SCOPE_CURRENT, oAuthOptions.scope);
        return true;
    }).catch(reason => {
        console.log('B2C SIGN IN CALL ERROR');
        console.log('Auth failed: '+ reason);

        return false;
    });
}

export const signinPortal = async () => {
    const currentUrl = window.location.href;
    var oauthOptions = await getOAuthOptions(true);
    if(currentUrl.includes('access_denied') && currentUrl.includes('The+user+has+forgotten+their+password'))
    {
        oauthOptions.authorizationBaseUrl = authConfig.AUTHORIZATION_PASSWORD_RESET_BASE_URL; 
    }
    return await OAuth2Client.authenticate(oauthOptions).then(async response => {
        let accessTokenResponse = response['access_token_response'];
        let jwtToken = accessTokenResponse['access_token'];
        await storePortalAuthData(jwtToken);
        return true;
    }).catch(reason => {
        console.log('(PORTAL) B2C SIGN IN CALL ERROR');
        console.log('(PORTAL) Auth failed: ' + reason);

        return false;
    });
}

export const signout = async (relogin: boolean) => {
    if (!relogin) {
        //logout from b2c
        const logoutUrl = getLogoutUrl();
        await Browser.open({ url: logoutUrl, windowName: "_self" }).then(() => setTimeout(() => {
            Browser.close();
        }, 2000));
    }

    return await OAuth2Client.logout(await getOAuthOptions(false)).then(async response => {
        await clearAuthData();
        return true;
    }).catch(reason => {
        console.log('Signout failed: ' + reason);
        return false;
    });
}

export const signoutPortal = async () => {
    return await OAuth2Client.logout(await getOAuthOptions(true)).then(async response => {
        await clearPortalAuthData();
        return true;
    }).catch(reason => {
        console.log('(PORTAL) Signout failed: ' + reason);
        return false;
    });
}

export const getBearerToken = async () => {
    return await get(BEARER_TOKEN).then(result => {
        if (!!!result) {
            console.log('Bearer token not found');
        }        
        return result;
    });        
}

export const getBearerTokenParsed = async () => {
    return await get(BEARER_TOKEN_PARSED).then(result => {
        if (!!!result) {
            console.log('Bearer token not found');
        }
        return result;
    });
}

export const getPortalBearerToken = async () => {
    return await get(BEARER_TOKEN_PORTAL).then(result => {
        if (!!!result) {
            console.log('Portal bearer token not found');
        }
        return result;
    });
}

export const getUserName = async (): Promise<string> => {
    return await get(BEARER_TOKEN_PARSED).then(token => {
        if (!!token) {
            let jwtParsed = JSON.parse(JSON.stringify(token));
            return jwtParsed.name;
        }
        return null;
    });
};

export const validateBearerToken = async () => {
    try {
        const token = await getBearerToken();
        if (!!!token) {
            return false;
        }
        //check if aud is valid for the current api scope
        if (await isB2CRefreshRequired()) {
            return false;
        }
        const tokenParsed = await get(BEARER_TOKEN_PARSED);
        if (!!tokenParsed) {
            let today = new Date();
            return (today.valueOf() < tokenParsed.exp * 1000);
        }
        return false;
    } catch (error) {
        console.log('ERROR (validateUserToken): ', error);
        return false;
    }
};

const isB2CRefreshRequired = async () => {
    const apiSession = await getApiSession();
    const currentScope = await get(B2C_SCOPE_CURRENT);
    if (!!apiSession && !!apiSession.B2CUserFlowScope && !!currentScope) {
        return currentScope !== apiSession.B2CUserFlowScope;
    }
    return false;
};

export const handlePWACallback = async (url: string, isPortal: boolean) => {
    const urlParams = JSON.stringify(getUrlParams(url));
    const pObj = JSON.parse(urlParams);
    const accessToken = pObj.access_token;
    if (accessToken !== undefined) {
        //store the token
        if (isPortal) {
            await storePortalAuthData(accessToken);
        } else {
            await storeAuthData(accessToken);
            //store b2c scope used
            getOAuthOptions(false).then((oAuthOptions) => {
                set(B2C_SCOPE_CURRENT, oAuthOptions.scope);
            });
        }
        return true;
    } else {
        console.log('Oauth Parameters where not present in return url');

        return false;
    }
};

const getOAuthOptions = async (isPortal: boolean) => {
    return {
        appId: authConfig.CLIENT_ID,
        authorizationBaseUrl: authConfig.AUTHORIZATION_BASE_URL,
        accessTokenEndpoint: authConfig.ACCESS_TOKEN_ENDPOINT,
        scope: await getScope(isPortal),
        logsEnabled: authConfig.LOGS_ENABLED,
        web: {
            pkceEnabled: authConfig.WEB.PKCE_ENABLED,
            responseType: authConfig.WEB.RESPONSE_TYPE,
            accessTokenEndpoint: authConfig.WEB.ACCESS_TOKEN_ENDPOINT,
            redirectUrl: (isPortal) ? authConfig.WEB.REDIRECT_URL_PORTAL : authConfig.WEB.REDIRECT_URL,
            windowTarget: authConfig.WEB.WINDOW_TARGET
        },
        android: {
            pkceEnabled: authConfig.ANDROID.PKCE_ENABLED,
            responseType: authConfig.ANDROID.RESPONSE_TYPE,
            redirectUrl: authConfig.ANDROID.REDIRECT_URL,
            accessTokenEndpoint: authConfig.ANDROID.ACCESS_TOKEN_ENDPOINT,
            handleResultOnNewIntent: authConfig.ANDROID.HANDLE_RESULT_ON_NEWINTENT,
            handleResultOnActivityResult: authConfig.ANDROID.HANDLE_RESULT_ON_ACTIVITYRESULT
        },
        ios: {
            pkceEnabled: authConfig.IOS.PKCE_ENABLED,
            responseType: authConfig.IOS.RESPONSE_TYPE,
            redirectUrl: authConfig.IOS.REDIRECT_URL,
            accessTokenEndpoint: authConfig.IOS.ACCESS_TOKEN_ENDPOINT
        }
    }
}

const getScope = async (isPortal: boolean) => {
    if (isPortal) {
        return authConfig.SCOPE_PORTAL;
    } else {
        const apiSession = await getApiSession();
        return apiSession.B2CUserFlowScope;
    }
};

const storeAuthData = async (token: string) => {
    await clearAuthData();
    await set(BEARER_TOKEN, token);
    await set(BEARER_TOKEN_PARSED, parseJwt(token));
}

const storePortalAuthData = async (token: string) => {
    await clearAuthData();
    await set(BEARER_TOKEN_PORTAL, token);
    await set(BEARER_TOKEN_PORTAL_PARSED, parseJwt(token));
    //reset mobilebootstrap data and apisession for new user
    await updatePortalUserData();
}

const clearAuthData = async () => {
    await remove(BEARER_TOKEN);
    await remove(BEARER_TOKEN_PARSED);
    //remove
    await remove(B2C_SCOPE_CURRENT);
}

const clearPortalAuthData = async () => {
    await remove(BEARER_TOKEN_PORTAL);
    await remove(BEARER_TOKEN_PORTAL_PARSED);
}

const updatePortalUserData = async () => {
    const jwtPortalParsed = await get(BEARER_TOKEN_PORTAL_PARSED);
    const portalUserUniqueId: string = await get(PORTAL_USER_UNIQUEID);
    if (jwtPortalParsed.sub !== portalUserUniqueId) {        
        //clear bootstrap data and api session data
        await clearApiSession();
        await clearApiOptionsData();
        //update user uniqueid
        await remove(PORTAL_USER_UNIQUEID);
        await set(PORTAL_USER_UNIQUEID, jwtPortalParsed.sub);
    }
};

const getLogoutUrl = () => {
    if (isPlatform('hybrid')) {
        if (isPlatform('android')) {
            return `${authConfig.LOGOUT_URL}?client_id=${authConfig.CLIENT_ID}&response_type=${authConfig.ANDROID.RESPONSE_TYPE}&post_logout_redirect_uri=${authConfig.ANDROID.POST_LOGOUT_REDIRECT_URL}&scope=${authConfig.SCOPE}`;
        } else if (isPlatform('ios')) {
            return `${authConfig.LOGOUT_URL}?client_id=${authConfig.CLIENT_ID}&response_type=${authConfig.IOS.RESPONSE_TYPE}&post_logout_redirect_uri=${authConfig.IOS.POST_LOGOUT_REDIRECT_URL}&scope=${authConfig.SCOPE}`;
        }
    }
    return `${authConfig.LOGOUT_URL}?client_id=${authConfig.CLIENT_ID}&response_type=${authConfig.WEB.RESPONSE_TYPE}&post_logout_redirect_uri=${authConfig.WEB.POST_LOGOUT_REDIRECT_URL}&scope=${authConfig.SCOPE}`;
}

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const getUrlParams = (url: string) => {
    const urlString = `${url}`.trim();
    if (urlString.length === 0) {
        return;
    }
    // #132
    let hashIndex = urlString.indexOf("#");
    let queryIndex = urlString.indexOf("?");
    if (hashIndex === -1 && queryIndex === -1) {
        return;
    }
    let paramsIndex;
    if (hashIndex > -1 && queryIndex === -1) {
        paramsIndex = hashIndex;
    }
    else if (queryIndex > -1 && hashIndex === -1) {
        paramsIndex = queryIndex;
    }
    else {
        paramsIndex = hashIndex > -1 && hashIndex < queryIndex ? hashIndex : queryIndex;
    }
    if (urlString.length <= paramsIndex + 1) {
        return;
    }
    const urlParamStr = urlString.slice(paramsIndex + 1);
    const keyValuePairs = urlParamStr.split(`&`);
    // @ts-ignore
    return keyValuePairs.reduce((accumulator, currentValue) => {
        const [key, val] = currentValue.split(`=`);
        if (key && key.length > 0) {
            return Object.assign(Object.assign({}, accumulator), { [key]: decodeURIComponent(val) });
        }
    }, {});
}