import { PlantCurrency } from "../models/plant/Plant";
import { get, set, remove } from "./Preferences";
import { getPlantCurrency } from "../api/PlantSetups";

const _plantCurrencyKey = "PlantCurrency";

export const getCurrency = async () => {
    return await get(_plantCurrencyKey) as PlantCurrency;
};

export const setCurrency = async (currency: PlantCurrency) => {
    await remove(_plantCurrencyKey);
    await set(_plantCurrencyKey, currency);
};

export const loadPlantCurrency = async () => { 
    const response = await getPlantCurrency();
    if (response.status === 200) {
        await setCurrency(response.data);
    }
}